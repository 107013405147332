<template>
  <div>
    <el-row >
      
      <el-col :span="16" style="position:relative;">
              <svg-icon :data_iconName="$route.path=='/'?'logoBlack':'logoWhite'" :className="$route.path=='/'?'logoBlack_icon':'logoWhite_icon'" class="svgIcon svg"
            />
        <!-- <img :src="$route.path=='/'?require('@/assets/logoBlack.svg'):require('@/assets/logoWhite.svg')" alt="" class="pic"> -->
        <el-menu
          :default-active="navLeftActive"
          class="el-menu-demo TopMenu menu"
          background-color="rgb(0 0 0 / 0%)"
          mode="horizontal"
          active-background-color="#324d8d"
          :router="true"
          :collapse-transition="false"
        >
          <!-- :active-text-color="color=='rgb(15 15 15)'?'#0343dd':'#a0bcff'" -->
          <!-- :text-color="color" -->
          <template v-for="(item, index) in routerList">
            <el-submenu
              :index="item.path"
              :key="index"
              v-if="$route.path=='/'?(item.children ? item.children.length != 1 : ''):$route.meta.activeMenu==item.path?false:(item.children ? item.children.length != 1 : '')"
              class="submenu"
              :class="$route.path=='/'?'iBlack':'iwhite'"
            >
              <template slot="title">
                <!-- <i class="marginRight svgI" :style="{color:color}">
              <svg-icon :data_iconName="item.meta.icon" :className="item.meta.icon+'_icon'" class="svgIcon"
            /></i> -->
                <span slot="title" :style="$route.path=='/'?'color:black':'color:white'" >{{ item.meta.title }}</span>
              </template>
              <el-menu-item
                :index="itm.path"
                v-for="(itm, inx) in item.children"
                :key="inx"
                >{{ itm.meta.title }}</el-menu-item
              >
            </el-submenu>

            <el-menu-item
              :index="item.meta.menu != 1 ? item.path : item.children[0].path"
              v-if="
                $route.path=='/'? (item.children ?item.children.length == 1 : item.meta.menu == 2): $route.path!='/'&&$route.meta.activeMenu==item.path? item.children:(item.children ?item.children.length == 1 : item.meta.menu == 2)"
              :key="index"
              :style="$route.path=='/'?'color:black':'color:white'"
            >
              <!-- :index="item.children[0].path" -->
              <!-- <i class="marginRight svgI" :style="{color:color}">
              <svg-icon :data_iconName="item.meta.icon" :className="item.meta.icon+'_icon'" class="svgIcon"
            /></i> -->
              <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-col>
      <el-col :span="8" style="display:flex;align-items: center;justify-content: flex-end;padding-right: 5%;">
        <!-- <el-input placeholder="请输入内容" v-model="inputSearch" class="inputSearch">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input> -->
        <search id="header-search" class="right-menu-item" :style="$route.path=='/'?'':'color:white'"/>
        <div class="login">
          <svg-icon className="login_icon" data_iconName="login" :style="$route.path=='/'?'':'color:white'"/>
          <!-- <el-button>登录</el-button> -->
          <!-- <el-button>注册</el-button> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Search from '@/components/HeaderSearch'
import {jbrowse} from "@/api/dataList";

export default {
  inject: ["reload"],
  name: "HelloWorld",
  data() {
    return {
      activeIndex: "1",
      name: this.$Storage.getItem("adm_name"),
      routerList: [],
      inputSearch:'',
      options:[]
    };
  },
  methods: {
    getMenuLinks() {
      var t = this.$router.options.routes.filter(function (val, index, arr) {
        // console.log(val,"val")
        if(val.name!="detail"){
          return val.children;
        }
      });
      this.routerList = t;
      // if(this.$route.path!="/"){
      //   console.log(this.routerList)
      //   //  var menuList=[]
      //   //   for(var i=0;i<this.$router.options.routes.length;i++){
      //   //     for(var a=0;a<this.$router.options.routes[i].children.length;a++){
      //   //       if(this.$router.options.routes[i].children[a].path==this.$route.path){
      //   //         menuList=this.$router.options.routes[i].children
      //   //       }
      //   //     }
          
      //   //   }
      // }
     
      // //console.log(this.$router,this.$route);
      
      
      // var list=this.$route.meta.menu
      //console.log(list)
      // var arr=this.$router.options.routes.filter(function (val) {
        //console.log(val)
        // return val
      // })
      /* if(list.length==2){
        var arr1=[]
        for(var i=0;i<arr.length;i++){
          if(i==0){
            arr1.push(arr[i])
          }else{
            for(var o=0;o<arr[i].children.length;o++){
              if(arr[i].children[o].children){
                arr1.push({alwaysShow:false,children:arr[i].children[o].children,component:arr[i].component,menu:arr[i].menu,meta:arr[i].children[o].meta,name:arr[i].children[o].name,path:arr[i].children[o].path,redirect:arr[i].children[o].redirect})
              }else{
                arr1.push({alwaysShow:arr[i].alwaysShow,children:[arr[i].children[o]],component:arr[i].component,menu:arr[i].menu,meta:arr[i].children[o].meta,name:arr[i].children[o].name,path:arr[i].children[o].path,redirect:arr[i].children[o].path})
              }
              
            }
          }
        }
         this.routerList = arr1; */
      // }else{
        //  this.routerList = arr;
      // }

      // console.log(arr)
    },
    refresh() {
      this.reload();
    },
    login() {
      this.$router.push({ path: "/login" });
    },
    logout() {
      this.$Storage.removeItem("adm_name");
      this.$Storage.removeItem("Admin-Token");
      this.name = this.$Storage.getItem("adm_name");
    },
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    navLeftActive: function () {
      //console.log(this.$route);
      const { meta, path } = this.$route; 
      console.log(meta, path, this.$route, 111);
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      this.getMenuLinks();
      return path; //"/" + path.split("/")[1];
    },
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    this.getMenuLinks();
  },
  components:{
    Search
  }
};
</script>

<style scoped>
.menu {
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 60px !important;
}
.svg{
  position: absolute;
    width: 120px !important;
    height: 53px !important;
}
.el-menu--horizontal {
  border: 0px !important;
  /* width: 80%; */
    margin-left: 30%;
}
.login {
  margin-left: 15px;
  margin-top: 7px;
  cursor: pointer;
}
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 2px solid #324d8d;
  color:#324d8d;
}
.el-menu--horizontal>.el-menu-item{
  height: 60px !important;
  line-height: 60px !important;
  font-size: 16px !important;/*no*/
  font-weight: bold;
  margin-right: 60px;
  /* color: white !important; */
}
.el-menu--horizontal>.el-menu-item :hover{
  color: black !important;
}
/* .el-menu--horizontal .el-menu .el-menu-item:hover{
  color: black !important;
  background: rgba(8, 138, 105, 0.455) !important;
} */
.el-menu--horizontal .el-menu .el-menu-item{
  color: black !important;
  background-color: white !important;
  font-size: 13px !important;
  
  /* border-bottom: 2px dashed rgba(0, 128, 0, 0.719); */
}
.el-menu--horizontal>.el-submenu{
  margin-right: 60px !important;
}
.el-menu--horizontal>.el-submenu:hover .el-submenu__title span{
  color: black !important;;
}
.el-menu--horizontal .el-menu .el-menu-item:hover{
  color: rgb(252, 251, 251) !important;
  background-color: #324d8d !important;
  transform:scale(1.1)
}

.inputSearch{
  width: 50%;
      margin-right: 20px;
    margin-left: -60px;
}
.pic{
  position: absolute;
    width: 140px;
    top: 5px;
    left: 10px;

}
</style>
<style>
.el-menu--horizontal .el-submenu__title{
  /* color: white !important; */
  font-size: 16px !important;/*no*/
  font-weight: bold;
}
.iblack .el-submenu__title i{
  color: black !important;
}
.iwhite .el-submenu__title i{
  color: white !important;
}
.el-menu--horizontal .el-submenu .el-submenu__title{
  height: 60px !important;
  line-height: 60px !important;
}
</style>
