import axios from 'axios'
import store from '@/store'
// import {setToken,getToken,removeToken} from '@/utils/auth'

var baseURL = '';
switch (process.env.NODE_ENV) {
    case 'development'://开发环境
        baseURL = '/api'
        break
    case 'production'://生产环境
        // baseURL = 'http://124.70.162.174:8009'
        baseURL = 'https://grd.smartgenomics.net/api' //https://60.30.67.242:8093
        break
}
axios.defaults.baseURL = baseURL;//设置axios默认地址
console.log(process.env.VUE_APP_BASE_API)
// 自定义配置创建axios的新实例
const service = axios.create({
	baseURL: process.env.BASE_API, // api 的 base_url 将被添加到`url`前面
	timeout: 60000, // request timeout
	params: {} ,// 无论请求为何种类型，在params中的属性都会以key=value的格式在urlzhong拼
	// headers: {
	//	 'Access-Control-Allow-Methods': '*',
	//	 'Access-Control-Allow-Headers': 'X-Custom-Header',
	//	 'Access-Control-Allow-Credentials': true,
	//	 'Access-Control-Max-Age': '1728000'
	// }
	// headers:{
	// 	'Access-Control-Allow-Headers': 'X-Custom-Header',
	// 	Authorization:localStorage.getItem('Admin-Token')
	// }
})
// request interceptor 添加请求拦截器
service.interceptors.request.use(
	config => {
		console.log(config)
		// 在发送请求之前做某事
		config.cancelToken = new axios.CancelToken((cancel) => {
			store.commit("set_request_url", { cancelToken: cancel });
		  });
		// const toKen = localStorage.getItem('Admin-Token');
		// console.log(getToken('Admin-Token'))
		// if(toKen){
		// 	// config.headers.common['Authorization']=toKen
		// 	// setToken('Admin-Token',toKen)
		// 	if(config.data){
		// 		// config.data.token = toKen;
		// 	}
			
		// }else{
		// 	// setToken('Admin-Token',toKen)
		// 	if(config.data){
		// 		// config.data.token = '';
		// 	}
			
		// }
		return config
	},
	error => {
		
		// Do something with request error //请求错误时做些事
		// console.log('err111111111', error) // for debug
		// return error
		Promise.reject(error) // 返回一个被拒绝的Promise对象 用于调试
	}
)

// response 成功 拦截器
service.interceptors.response.use(
	response =>{
		const res = response.data;
		// console.log( response.data.code)
		if (res.code === 1000) {
			if (res.code === 1000) {
				// MessageBox.confirm('您已被登出，请重新登录', '确定登出', {
				//	 confirmButtonText: '重新登录',
				//	 // cancelButtonText: '取消',
				//	 showCancelButton:false,
				//	 type: 'warning'
				// }).then(() => {
					// removeToken('Admin-Token')
					localStorage.removeItem('Admin-Token')
					router.push("/login")
					/* store.dispatch('LogOut').then(() => {
						location.reload() // 为了重新实例化vue-router对象 避免bug
					}) */
				// })
			}
			return Promise.reject('error');
		} else {  
			 return response
		}
		// else if(res.ErrorCode === 1003){
		//			 MessageBox.confirm(res.ErrorMessage, '确定', {
		//				 confirmButtonText: '确定',
		//				 // cancelButtonText: '取消',
		//				 showCancelButton:false,
		//				 type: 'warning'
		//			 }).then(() => {
		//				 // store.dispatch('FedLogOut').then(() => {
		//				 //	 location.reload() // 为了重新实例化vue-router对象 避免bug
		//				 // })
		//				 // this.$router.go(-1);
		//				 next()
		//			 })
		//			 return Promise.reject('error');
		// } 
	},
	error =>{
		if (error.response) {
			console.log(error.response)
		}
		// return error.response
		return Promise.reject(error)

	}
)

export default service
