<template>
  <div>
      <el-container>
        <el-header
          
          v-if="routeData.indexOf($route.name) === -1"
          class="navBox"
          :class="
            routeData1.indexOf($route.name) === -1 ? 'greenNav' : ''
          "
        >
          <!-- 菜单组件 -->
          <menu-tab />
        </el-header>
        <!-- 内容部分 -->
        <el-main
          id="main1"
          :class="
            routeData1.indexOf($route.name) === -1 ? 'mainHeight' : 'overHeight'
          "
          class="main"
        >
        <el-container v-if="routeData1.indexOf($route.name) === -1">
          <el-aside id="aside" :style="{width:bol?'100px':'270px'}" >
            <img src="@/assets/aside.png" alt="" :class="{imgShou:bol}" :style="{width:'100%'}">
            <left-menu ref="leftMenuref"/>
          </el-aside>
          
          <el-main class="innerMain">
            <router-view
            :class="
              routeData.indexOf($route.name) === -1 ? 'pading' : 'overPading'
            "
          ></router-view>
          </el-main>
           <!-- <el-footer  class="footer">
            <el-row class="footerRow">
              <el-col :span="10">
                <el-col :span="12" style="text-align: right;">
                  <img src="@/assets/ma.jpeg" alt="" class="footerPic">
                </el-col>
                <el-col :span="12" class="footerText">
                  <div>022-29503270</div>
                  <div>www.smartgenomics.net</div>
                  <div>support@smartgenomics.cn</div>
                  <div>天津市武清商务区国际企业社区A9座2层</div>
                </el-col>
              </el-col>
              <el-col :span="14"  class="footerRightText">
                <div>ABOUT US</div>
                <div>关于极智</div>
                <div>连接官网</div>
              </el-col>
            </el-row>
          </el-footer> -->
        </el-container>
          <!-- 展示路由 -->
          <router-view
          v-else
            :class="
              routeData.indexOf($route.name) === -1 ? 'pading' : 'overPading'
            "
          ></router-view>
           <el-backtop target=".main"></el-backtop>
          <el-footer  v-if="routeData1.indexOf($route.name) !== -1 " class="footer">
            <el-row class="footerRow">
              <el-col :span="10">
                <el-col :span="8" style="text-align: right;">
                  <img src="@/assets/logofooter.png" alt="" class="footerPic">
                </el-col>
                <el-col :span="16" class="footerText">
                  <div>022-29503270</div>
                  <div>www.smartgenomics.net</div>
                  <div>support@smartgenomics.cn</div>
                  <div>天津市武清商务区国际企业社区A9座2层</div>
                </el-col>
              </el-col>
              <el-col :span="14"  class="footerRightText">
                <div>ABOUT US</div>
                <div style="color:black;">关于极智</div>
                <div><a href="https://smartgenomics.net/" style="color: #324d8d;text-decoration: none;" target="_blank">天津极智基因科技有限公司</a></div>
              </el-col>
            </el-row>
          </el-footer>
          <!-- <foot-nav v-if="routeData.indexOf($route.name) === -1" class="footBox"/> -->
        </el-main>
        <!-- <el-footer> -->
          
        <!-- </el-footer> -->
      </el-container>
  </div>
</template>

<script>
import MenuTab from "@/components/MenuTab/index.vue";
import leftMenu from "@/components/MenuTab/leftMenu.vue";
export default {
  name: 'home',
   props:{
   //传值
   },
  data() {
    return {
        routeData: ["login"],
        routeData1: ["首页"],
      isRouterAlive: true,
      bol:false,

    };
  },
  components:{
    //引入模块
    MenuTab,
    leftMenu
  },
  methods: {
    // 组件的方法
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
    
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  beforeCreate: function() {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function() {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function() {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  mounted: function() {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log('Home');
    this.$watch(
      () => {
       return this.$refs.leftMenuref?this.$refs.leftMenuref.isCollapse:true
      },
      (val) => {
         this.bol=this.$refs.leftMenuref.isCollapse
      }
    )
  },
  beforeUpdate: function() {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function() {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function() {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function() {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  }
};

</script>
<style scoped>
.el-container{
    height: 100vh;
    /* font-size:14px; */
}
.innerMain{
  /* background: #eee; */
  padding: 50px;
}
.mainHeight {
  overflow: hidden;
}
.overHeight {
  /* height: calc(120vh - 0rem); */
  overflow-x: hidden !important;
  overflow-y: auto;
}
.pading {

}
.overPading {
  padding: 0 !important;
}
.navBox {
  /* background: red; */
  
  height: 60px !important;
  line-height: 60px;
  border-bottom: 1px solid #08308f;
  /* background: #eee; */
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  /* height: 200px;
  height: calc(100vh - 80px) !important; */
}
.greenNav{
  background: #324d8d;
}

.topNav {
  background: linear-gradient(
    270deg,
    rgba(72, 109, 139, 1) 0%,
    rgba(123, 194, 235, 1) 100%
  );
  height: 80px !important;
  padding: 0 50px 0 10px;
}
.footBox {
  margin-top: 30px;
}
.main{
  padding: 0;
  /* overflow-x: hidden; */
}
.el-aside{
  /* width: 270px !important; */
  /* max-width: 270px; */
  position: relative;
  /* background-image: url("@/assets/aside.png"); */
}
.el-aside img{
  /* content: ""; */
  width: 251px;
  height: 100vh;
  /* background: url("@/assets/aside.png"); */
  /* background-size: cover; */
   position: absolute;
    top: 0;
    left: 0;
    z-index: -1;/*-1 可以当背景*/
    /* -webkit-filter: blur(3px); */
    /* filter: blur(3px); */
    filter: opacity(0.4);
}
.imgShou{
  width: 100px !important;/*no*/
}
.footer{
  background-image: url("@/assets/footer.png");
  background-size: 100% 100%;
  height: 170px !important;
  padding: 0px 260px;
}
.footer .footerRow{
  padding-top: 20px;
    padding-bottom: 20px;
    width: 70%;
    margin: auto;
   color: white;
}
.footer .footerPic{
  width: 60%;
    margin-right: 10px;
}
.footer .footerText div{
  line-height: 30px;
  font-size: 14px;
  font-weight: bold;
}
.footer .footerRightText{
  text-align: center;
}
.footer .footerRightText div{
  margin-bottom: 10px;
  font-weight: bold;
}
.footer .footerRightText div:nth-child(1){
  font-size: 20px;
  color: #324d8d;
}
</style>
<style>

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app,
.box {
  height: 100%;
}
.el-container {
  height: 100%;
}

a:hover{
  cursor: pointer;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 8px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 3px;
  box-shadow: inset 0 0 5px #324d8d;
  background: #324d8d;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px #324d8d;
  border-radius: 3px;
  background: #ededed;
}
.el-table--medium .el-table__cell{
  padding: 0px !important;
}
.greenBu{
  background: #324d8d !important;
  border-color: #324d8d !important;
  color: white !important;
}
.greenEg{
  color: #324d8d !important;
}
.mainBox {
  background: white;
    padding-top: 20px;
    margin: 0 auto;
    min-height: calc(100vh - 120px);
}
.titleBox{
  font-size: 20px !important;
  font-weight: bold;
}

</style>
<style>
.el-pagination .el-select .el-input{
  width: 140px !important;
}
.el-radio__input.is-checked .el-radio__inner{
  background: #324d8d !important;
  border-color: #324d8d !important;
}
.el-radio__input.is-checked+.el-radio__label{
  color: #324d8d !important;
  border-color: #324d8d !important;
}
</style>